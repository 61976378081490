<template>
  <div class="vx-col w-full mb-5" >
    <h6>{{f.field_name}} </h6>
     <vx-tooltip 
      :title="f.field_name"
      color="primary"
      style="float:right;cursor:pointer;margin-top:-8px"
      :text="f.indication">
      <vs-chip color="primary">?</vs-chip>
    </vx-tooltip>
    <vs-button color="primary" type="border" icon-pack="feather" class="mb-4 mt-3"  @click="addRepresentative()">Agregar representante</vs-button>
        
      <vs-table stripe :data="representatives" noDataText="No se han generado registros">
        <template slot="thead">
          <vs-th class="bold">Primer nombre</vs-th>
          <vs-th class="bold">Segundo nombre</vs-th>
          <vs-th class="bold">Apellido paterno</vs-th>
          <vs-th class="bold">Apellido materno</vs-th>
          <vs-th class="bold">ACCIONES</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].first_name">
              {{ data[indextr].first_name }}
            </vs-td>

            <vs-td :data="data[indextr].second_name">
              {{ data[indextr].second_name }}
            </vs-td>

            <vs-td :data="data[indextr].last_name_1">
              {{ data[indextr].last_name_1 }}
            </vs-td>

            <vs-td :data="data[indextr].last_name_2">
              {{ data[indextr].last_name_2 }}
            </vs-td>

            <vs-td :data="data[indextr].id">
              <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" class="ml-6" @click.stop="removeItemRequest(data[indextr])" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup
        title="Agregar representante"
        :active.sync="showFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>
          <vs-alert v-if="onSuccess" class="h-full mb-5" color="success">
            <span>{{ successMssg }}</span>
          </vs-alert>

          <p class="mb-5 bold">{{formPromptIndication}}</p>

          <vs-input
            class="w-full mb-4" 
            label="Primer nombre"
            placeholder="Requerido"
            v-validate="'required'"
            data-vv-name="first_name"
            :danger="hasError('first_name')"
            :danger-text="errorText('first_name')"
            :success="isSuccess('first_name')"
            v-model.lazy="first_name" />  

          <vs-input
            class="w-full mb-4" 
            label="Segundo nombre"
            placeholder="Requerido"
            v-validate="'required'"
            data-vv-name="second_name"
            :danger="hasError('second_name')"
            :danger-text="errorText('second_name')"
            :success="isSuccess('second_name')"
            v-model.lazy="second_name" />  

          <vs-input
            class="w-full mb-4" 
            label="Apellido paterno"
            placeholder="Requerido"
            v-validate="'required'"
            data-vv-name="last_name_1"
            :danger="hasError('last_name_1')"
            :danger-text="errorText('last_name_1')"
            :success="isSuccess('last_name_1')"
            v-model.lazy="last_name_1" />  

          <vs-input
            class="w-full mb-4" 
            label="Apellido materno"
            placeholder="Requerido"
            v-validate="'required'"
            data-vv-name="last_name_2"
            :danger="hasError('last_name_2')"
            :danger-text="errorText('last_name_2')"
            :success="isSuccess('last_name_2')"
            v-model.lazy="last_name_2" />  

          <vs-button :disabled="!completeForm || $isLoading" @click="saveRepresentative" color="primary" class="mr-5 mt-2">Agregar</vs-button>
          <vs-button @click="closeFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cerrar</vs-button>
            
        </div>
      </vs-popup>

    </div>

</template>

<script>
import inputHelper from "@mixins/inputHelper"
const indicationDef = "Ingresa los datos solicitados.";
const errorMssgDef = "Ha ocurrido un error al agregar al representante, intente más tarde."
const successMssgDef = "El representante ha sido agregado exitosamente"
const statePresenceVal = 0;
const cityPresenceVal = 1;

export default {
  name: 'business-representatives-control',
  mixins: [inputHelper],
  props: {
    f: { type: Object, required: true },
    business_profile_id: { type: [String, Number], required: true },
  },
  data(){
    return {
      first_name:null,
      second_name:null,
      last_name_1:null,
      last_name_2:null,

      representatives: [],

      showFormPrompt: false,
      selectedRepresentative: null,

      onError: false,
      onSuccess: false,
      errorMssg: errorMssgDef,
      successMssg: successMssgDef,
      formPromptIndication: indicationDef,
    }
  },
  mounted: async function(){
    await this.loadSavedRepresentatives();
  },
  computed: {
    completeForm(){
      return this.first_name != null && this.second_name != null && this.last_name_1 != null && this.last_name_2 != null;
    }
  },
  methods: {
    async loadSavedRepresentatives(){
      try {
        let res = await axios.get(`/api/v1/business/${this.$props.business_profile_id}/getRepresentatives`);
        let collection = res.data;
        this.representatives = [];

        collection.forEach(f => {
          let tt = { id: f.id, first_name: f.first_name, second_name: f.second_name, last_name_1: f.last_name_1, last_name_2: f.last_name_2};
          this.representatives.push(tt);
        });
      }
      catch (e) {
        this.warn(e);
      }      
    },
    removeItemRequest(representative){
      this.selectedRepresentative = representative;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Eliminar representante',
        text: `¿Realmente desea eliminar el representante?`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveItem
      });
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {representative_id: this.selectedRepresentative.id};
        await axios.post(`/api/v1/business/${this.$props.business_profile_id}/removeRepresentative`, payload);
        await this.loadSavedRepresentatives();
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    async saveRepresentative(){
      this.onSuccess = false;
      this.onError = false;
      
      this.showLoading(true);
      try {        
        let payload = { first_name: this.first_name, second_name: this.second_name, last_name_1: this.last_name_1,last_name_2: this.last_name_2};
        await axios.post(`/api/v1/business/${this.$props.business_profile_id}/insertRepresentative`, payload);
        this.onSuccess = true;
        await this.loadSavedRepresentatives();
        this.closeFormPrompt();
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    addRepresentative(){
      this.resetForm();
      this.showFormPrompt = true;
    },
    resetForm(){
      this.first_name = null
      this.second_name = null
      this.last_name_1 = null
      this.last_name_2 = null
      this.state_id = null
      this.presence = null
      this.city_id = null
      this.onError = null
      this.onSuccess = null      
      this.sent = null
    },
    closeFormPrompt() {
      this.showFormPrompt = false;
      this.resetForm();
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);      
    },

    /* check validation */
    validate(){
      return this.representatives.length >= 1;
    }
  }
}
</script>